import React from "react"
import NewsletterForm from "../components/NewsletterForm"
import CareersEquality from "../sections/OldDesign/careers/CareersEquality"
import CareersHelmet from "../sections/OldDesign/careers/CareersHelmet"
import CareersHero from "../sections/OldDesign/careers/CareersHero"
// import CareersPositions from "../sections/OldDesign/careers/CareersPositions"
import CareersTalent from "../sections/OldDesign/careers/CareersTalent"
import CareersTeam from "../sections/OldDesign/careers/CareersTeam"
import Layout from "../templates/Layout"

const Careers = () => {
    return (
        <Layout>
            <div className="bg-horizen-hp-bg">
                <div className="min-h-[680px] mx-auto pt-10 md:pt-16 md:bg-contain bg-no-repeat bg-media-hero">
                    <CareersHelmet />
                    <CareersHero />
                    <CareersTalent />
                    <CareersTeam />
                    {/* <CareersPositions /> */}
                    <CareersEquality />
                    <NewsletterForm />
                </div>
            </div>
        </Layout>
    )
}

export default Careers
