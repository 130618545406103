import React from "react"
import ContentContainer from "../../../templates/ContentContainer"
import Link from "../../../components/OldDesign/Link"

const CareersEquality = () => {
    return (
        <div className="bg-contain bg-no-repeat bg-left-bottom bg-media-products">
            <section className="py-36">
                <ContentContainer>
                    <div className="relative">
                        <div className="absolute top-0 left-0 h-0.5 w-[14px] bg-white" />
                        <div className="absolute bottom-0 left-0 h-0.5 w-[14px] bg-white" />
                        <div className="absolute top-0 right-0 h-0.5 w-[14px] bg-white" />
                        <div className="absolute bottom-0 right-0 h-0.5 w-[14px] bg-white" />
                        <div className="flex items-center flex-col md:flex-row border-l-2 border-r-2 border-white py-12 px-4 md:px-12">
                            <div className="md:w-1/3">
                                <h3 className="text-[22px] font-bold text-white px-4 md:pr-20 mb-4 md:mb-0">
                                    Horizen is an equal opportunity employer.
                                </h3>
                            </div>
                            <div className="md:w-2/3">
                                <p className="text-lg text-[#C8CFD4] px-4 md:px-12">
                                    Our team is committed to providing
                                    reasonable accommodations to individuals
                                    with disabilities. If you need a reasonable
                                    accommodation for any part of the employment
                                    process, please contact us at{" "}
                                    <Link href="mailto:recruitment@horizen.io">
                                        recruitment@horizen.io
                                    </Link>{" "}
                                    and let us know the nature of your request.
                                </p>
                            </div>
                        </div>
                    </div>
                </ContentContainer>
            </section>
        </div>
    )
}

export default CareersEquality
