import React from "react"
import teamImg1 from "../../../assets/OldDesign/careers/NA_V2008.jpg"
import teamImg2 from "../../../assets/OldDesign/careers/NA_V3704.jpg"
import teamImg3 from "../../../assets/OldDesign/careers/NA_V1358.jpg"
import teamImg4 from "../../../assets/OldDesign/careers/PXL_20220612_020334879.MP_mr1655081386305.jpg"
import teamImg5 from "../../../assets/OldDesign/careers/PXL_20220913_101331839.MP_mr1663074035275.jpg"
import teamImg6 from "../../../assets/OldDesign/careers/PXL_20220912_170823912.MP.jpg"
import ContentContainer from "../../../templates/ContentContainer"

const imageSliderData = [
    {
        img: teamImg1,
    },
    {
        img: teamImg2,
    },
    {
        img: teamImg3,
    },
    {
        img: teamImg4,
    },
    {
        img: teamImg5,
    },
    {
        img: teamImg6,
    },
]

const CareersTeam = () => {
    return (
        <section className="2xl:container 2xl:mx-auto">
            <ContentContainer>
                <div className="flex flex-col md:flex-row justify-center text-white">
                    <div className="basis-1/2 flex items-center">
                        <p className="text-5xl font-[700]">The Horizen Team</p>
                    </div>
                    <div className="basis-1/2">
                        <p className="mt-4 mb-4 font-proxima text-lg">
                            Horizen is a project with a strong vision and is not
                            afraid to look for the best solutions to achieve it.
                            This vision fuels our drive to be a technological
                            pioneer in the industry. We have a globally diverse
                            and uniquely rich background that includes some of
                            the finest minds in the industry.
                        </p>
                        <p className="mt-4 mb-4 font-proxima text-lg">
                            We are united with our community. Our dedication to
                            transparency and inclusion inspires an active and
                            passionate community. Together, we have built an
                            ecosystem where everyone can belong and thrive.
                        </p>
                    </div>
                </div>
            </ContentContainer>
            <div className="overflow-hidden my-10">
                <div className="inline-flex">
                    <div className="rotating-1 flex">
                        {imageSliderData.map((info, i) => (
                            <div
                                key={i}
                                className="w-[450px] flex flex-col justify-center items-center text-center h-[300px] shadow-[0px_10px_40px_0px_#0000000D] mx-3 my-10"
                            >
                                <img src={info.img} />
                            </div>
                        ))}
                    </div>
                    <div className="rotating-2 flex">
                        {imageSliderData.map((info, i) => (
                            <div
                                key={i}
                                className="w-[450px] flex flex-col justify-center items-center h-[300px] shadow-[0px_10px_40px_0px_#0000000D] mx-3 my-10"
                            >
                                <img src={info.img} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CareersTeam
