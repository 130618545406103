import React from "react"
import ContentContainer from "../../../templates/ContentContainer"
import Brackets from "../../../components/Brackets"

const CareersTalent = () => {
    return (
        <section className="pb-36 md:pb-12">
            <ContentContainer>
                <div className="flex gap-y-7 gap-x-14 flex-col md:flex-row text-white">
                    <div className="basis-1/2">
                        <p className="font-[700] text-[22px]">
                            Come work with us.
                        </p>
                        <p className="mt-4 mb-4 font-proxima text-lg">
                            We are looking for talented people who share our
                            vision of building a fairer and more inclusive world
                            through information integrity and data privacy by
                            building the most secure and scalable blockchain
                            ecosystem.
                        </p>
                    </div>
                    <div className="basis-1/2 items-center justify-center md:justify-end flex">
                        <Brackets className="mt-6" color="#0E9DE5">
                            <a
                                // href="#section-open-positions"
                                // target="_self"
                                href="https://www.linkedin.com/company/horizenlabs/jobs/"
                                target="_blank"
                                rel="noreferrer"
                                className="block text-center text-white font-[700] text-[20px] tracking-[0.3em] px-8 py-4 uppercase w-full sm:w-fit"
                            >
                                VIEW OPEN POSITIONS
                            </a>
                        </Brackets>
                    </div>
                </div>
            </ContentContainer>
        </section>
    )
}

export default CareersTalent
